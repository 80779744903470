import React from "react"
import { PageProps } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage: React.FC<PageProps<{}>> = () => (
  <Layout>
    <SEO title="Monomy Art" />
    <h1>Monomy Art</h1>
  </Layout>
)

export default IndexPage
